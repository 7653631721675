import React from "react";
import { Link } from "react-router-dom";
import { Phone, Envelope } from "react-bootstrap-icons";
const NavBar = () => {
  return (
    <>
      <div className="bg-nav"></div>

      <header className="main-header sticky-nav">
        <div className="mini-header">
          <div className="container">
            <ul className="mini-header-list">
              <li>
              <Phone /> <span>+91 7947239011</span>
              </li>

              <li>
              <Envelope />
                  <span> contact@fixscreenrepair.com</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="container header-div">
          <button id="nav-show" className="nav-btn-toggle hide-btn">
          
            <span className="line"></span> <span className="line"></span>
            <span className="line"></span>
          </button>

          <div className="location-label" id="" name="locationindex">
            <label id="locationindex"></label> <span className="caret"></span>
          </div>

          <div className="logo-div">
          
          <Link to="/">
             
              <img src="images/logo-repair.png" alt="logo-repair-logo" />
            </Link>{" "}
          </div>
          <div className="menu-div hide-on-mobile">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/gallery">Gallery</Link>
              </li>
              <li>
                <Link to="/offers">Offers</Link>
              </li>
              <li>
                <Link to="/blog">Blog</Link>
              </li>
              <li>
                <Link to="/contact">Contacts</Link>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </>
  );
};

export default NavBar;
