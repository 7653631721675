import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';


function About() {


 
  return (
    <div>





      <section className="padding-top">
        <div className="section-about transparent-bg about-banner">
          <img src="images/about-us.jpg" alt="FixScreenRepair aboutus" />
        </div>
      </section>

      <section className="section-padding">
        <div className="container">
          <div className="about-dv">
            <div className="about-text">
              <div className="section-title">
                <h1>
                  <span>
                    Mobile Phone Screen Repair and Replacement Service by
                    FixScreenRepair
                  </span>
                </h1>
              </div>
              <p>
                Welcome at <span>Fix Screen Repair</span>. We are a leading
                mobile repairs company in India based in Noida and we having
                team of passionate and highly expert engineers who wants to
                revolutionize the mobile repairs industry by making your mobile
                repairs service process as smooth as your purchase of new
                mobile.
              </p>
              <p>
                When any person give their mobile phone repair at{" "}
                <span>Fix Screen Repair</span> than we starts with a free
                analysis to find the main problem and allow the technician to
                determine the right mobile phone screen repair path. Once the
                analysis of phone complete, the technician can explain all of
                your mobile phone screen repair options and get started if you
                want to proceed. We at <span>FixScreenRepair</span> promise to
                serve you with the best of our ability and resources available
                herewith.
              </p>
              <p>
                <span className="view-btn">
                  View Repair Price
                </span>
              </p>
            </div>
            <div className="about-video">
              <iframe
                title="FixScreenRepair video"
                src="https://www.youtube.com/embed/uJsfxzo8cYg"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen=""
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      <div>

 
    </div>
      <section className="section-padding bg-img-gray">
        <div className="container">
          <div className="section-title">
            <h2>
              <span>Offering You The Best Solution For Your Mobile Repair</span>
            </h2>
          </div>

          <div className="offering-text">
            <span className="text-screenpro">Fix Screen Repair</span> is a leading
            mobile phone repair service provider based in Ghaziabad, Delhi/NCR
            India. We specialize in all mobile phone repairs & replacement. Our
            repairs are conducted by highly trained technicians with sound
            knowledge & they having more than 8 years of experience in this
            field.
          </div>

          <div className="service-main">
            <div className="service-col">
              <div className="service-icon">
                <img src="images/easy-2.png" alt="FixScreenRepair easy" />
              </div>
              <div className="service-step-div">Always Available For You</div>
              <p>
                We at <span className="text-screenpro">FixScreenRepair</span> try to
                always available for our Customer Service. Our Top priority is
                Customer satisfaction which is more important than anything
                else. We are just one phone call away with our Free Pickup and
                delivery policy.
              </p>
            </div>
            <div className="service-col">
              <div className="service-icon">
                <img src="images/tag.png" alt="FixScreenRepair tag" />
              </div>

              <div className="service-step-div">Unbeatable Price</div>
              <p>
                Our mobile repair price of your device will be very affordable.
                You will get the best market price for any part repair &
                replacement of your device from{" "}
                <span className="text-screenpro">FixScreenRepair</span>. Our
                warranty starts from 6 Months to Lifetime. To know more visit
                our website today or Call us.
              </p>
            </div>
            <div className="service-col">
              <div className="service-icon">
                <img
                  src="images/gaurantee.png"
                  alt="FixScreenRepair gaurantee"
                />
              </div>
              <div className="service-step-div">Express Service</div>
              <p>
                We know how important a mobile phone is in our day to day life
                and for this <span className="text-screenpro">FixScreenRepair</span>{" "}
                provides same day Free Pickup and delivery service. Our expert
                will collect your phone at your home or office faster than
                anyone else.
              </p>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </section>

      <section>
        <div className="estimate-section bg-banner-contact">
          <div className="estimate-txt-center">
            <h2>Looking For A Fast & Reliable Repair Service</h2>
            <p>Get instant repair prices in just a few clicks</p>
            <p>
              <span className="view-btn">
                Get Your Phone Estimate
              </span>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;
