import React from "react";
import { Link } from "react-router-dom";
function Offers() {
  return (
    <div>
      <section className="offer-sec">
        <div className="container">
          <div className="section-title">
            <h1>
              <span>FixScreenRepair Coupon Codes & Offers</span>
            </h1>
          </div>
          <div className="offer-main">
            <div className="offer-full-col">
              <div className="offer-img">
                <img src="images/offer1.jpg" alt="FixScreenRepair-offer1" />
              </div>
              <div className="offer-code-detail">
                <h2>Iphone Mobile Screen Repair Offer</h2>
                <p>
                  Valid only Iphone mobile screen replacement mentioned on the
                  website. Use coupon code to avail the offer.
                </p>
                <div>
                <Link to="/"> Click here</Link> to show
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
            <div className="offer-full-col">
              <div className="offer-img">
                <img src="images/offer2.jpg" alt="FixScreenRepair-offer2" />
              </div>
              <div className="offer-code-detail">
                <h2>Vivo Mobile Screen Repair Offer</h2>
                <p>
                  Valid only Vivo mobile screen replacement mentioned on the
                  website. Use coupon code to avail the offer.
                </p>
                <div>
                <Link to="/"> Click here</Link> to show
                </div>
              </div>
              <div className="clearfix"></div>
            </div>

            <div className="offer-full-col">
              <div className="offer-img">
                <img src="images/offer3.jpg" alt="FixScreenRepair-offer3" />
              </div>
              <div className="offer-code-detail">
                <h2>Flat offer Valid All Brands Mobile Screen Repair</h2>
                <p>
                  All Brands mobile screen replacement mentioned on the website.
                  Use coupon code to avail the offer.
                </p>
                <div>
                <Link to="/"> Click here</Link> to show
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
            <div className="offer-full-col">
              <div className="offer-img">
                <img src="images/offer4.jpg" alt="FixScreenRepair-offer4" />
              </div>
              <div className="offer-code-detail">
                <h2>Oppo Mobile Screen Repair Offer</h2>
                <p>
                  Valid only Oppo mobile screen replacement mentioned on the
                  website. Use coupon code to avail the offer.
                </p>
                <div>
                <Link to="/"> Click here</Link> to show
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
            <div className="offer-full-col">
              <div className="offer-img">
                <img src="images/offer6.jpg" alt="FixScreenRepair-offer5" />
              </div>
              <div className="offer-code-detail">
                <h2>Xiaomi Mobile Screen Repair Offer</h2>
                <p>
                  Valid only Xiaomi mobile screen replacement mentioned on the
                  website. Use coupon code to avail the offer.
                </p>
                <div>
                <Link to="/"> Click here</Link> to show
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
            <div className="offer-full-col">
              <div className="offer-img">
                <img src="images/offer7.jpg" alt="FixScreenRepair-offer6" />
              </div>
              <div className="offer-code-detail">
                <h2>Samsung Mobile Screen Repair Offer</h2>
                <p>
                  Valid only Samsung mobile screen replacement mentioned on the
                  website. Use coupon code to avail the offer.
                </p>
                <div>
                <Link to="/"> Click here</Link> to show
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
            <div className="offer-full-col">
              <div className="offer-img">
                <img src="images/offer8.jpg" alt="FixScreenRepair-offer7" />
              </div>
              <div className="offer-code-detail">
                <h2>PnePlus Mobile Screen Repair Offer</h2>
                <p>
                  Valid only PnePlus mobile screen replacement mentioned on the
                  website. Use coupon code to avail the offer.
                </p>
                <div>
                <Link to="/"> Click here</Link> to show
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Offers;
