import React from "react";
import { Link } from "react-router-dom";
function Blog() {
  return (
    <div>
      <div className="container blog-container">
        <div className="blog-main-dv">
          <div className="blog-post-main">
            <div id="blog-7">
              <div className="blog-section-img">
                {" "}
                <img
                  src="images/blog-15-3-2021.jpg"
                  alt="FixScreenRepair Save money and time"
                />{" "}
              </div>
              <div className="blog-section-content">
                <h4>What is Doorstep Mobile Repair</h4>
                <p>
                  Doorstep Mobile repair is a convenient service in which we
                  send a certified professional technician at your doorstep with
                  all the repair tools and 100% quality spares. Our repair
                  executive will repair your device in front of you which also
                  makes it the safest way of getting one’s phone fixed.
                </p>
                <p>
                  Is there is any extra money charged for doorstep service or
                  free device pick-up &amp; drop?
                </p>
                <p>
                  No, the pick-up and drop facility is absolutely free. We do
                  not charge extra money for this service. A person has to only
                  pay the repair fees or bear the price of a new part
                </p>
                <p>Who will repair my phone in both the services?</p>
                <p>
                  Only a certified technician will operate on your device in
                  both the services. Our repair labs are equipped with
                  experienced technicians and tools required for performing any
                  repair task. In the case of the doorstep repair service, you
                  are paid a visit by the best repair specialist who too are
                  equipped with the right tools and knowledge.
                </p>
                <div>
                  <div className="blog-btn">
                    {" "}
                    <Link to="/" className="view-btn">
                      View Repair Price
                    </Link>
                  </div>

                  <div className="clearfix"></div>
                </div>
              </div>
            </div>

            <div id="blog-6">
              <div className="blog-section-img">
                {" "}
                <img
                  src="images/blog-16-12-2019.jpg"
                  alt="FixScreenRepair Save money and time"
                />{" "}
              </div>
              <div className="blog-section-content">
                <h4>Get Your IOS Android Phone At Your Doorstep Now</h4>
                <p>
                  With Onsite mobile repair in Delhi, Noida, Ghaziabad, now you
                  don’t have to go anywhere. With our busy lifestyle, it is hard
                  to find a good service centre then to wait for our damaged
                  phone to get fixed. But now you can get an expert team at your
                  doorstep easily. IPhone screen repair in Delhi, Noida,
                  Ghaziabad is now affordable and quick. The Great mobile repair
                  services with Fixscreenrepair now take care of everything.
                  They ensure cheaper rates than other service providers and
                  give you a quick solution. Today as most of us keep our
                  work-related or financial information on our mobile, handing
                  it over to a service centre doesn’t feel secure. But whether
                  it is about your All devices Like ISO, android screen repair
                  in Delhi, Noida, Ghaziabad or virus or malware problem. Now
                  you get both security and assurance that your data will be
                  safe. The best mobile repair in Delhi, Noida, Ghaziabad also
                  ensures three months to a lifetime warranty. Therefore from
                  affordability to security, you get it all.
                </p>
                <div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>

            <div id="blog-5">
              <div className="blog-section-img">
                {" "}
                <img
                  src="images/fixscreenrepair-save-money.jpg"
                  alt="FixScreenRepair Save money and time"
                />{" "}
              </div>
              <div className="blog-section-content">
                <h4>Benefits Of Mobile Repair Shop Near You</h4>
                <p>
                  {" "}
                  We are very busy in our day to day work and we don’t have much
                  time to get into another work without the mobile and now-a-day
                  mobile has become like life partner. The most interesting
                  thing about it is, you see on any street, you will find a lot
                  of vendors who will be providing this mobile related service,
                  either that service is related to buying a new mobile phone or
                  repairing your existing mobile phone and this has all happened
                  due to boost in the mobile phone industry and easy
                  availability of cheap touch screen mobile phones, having these
                  shops around the corner can actually be quite beneficial.
                  Right from screen repairs to glass repair, these mobile repair
                  shops provide almost all services. They are the most used and
                  most popular type of service you will find in the market
                  today.{" "}
                </p>
                <p>
                  With the emergence in technology and increased use of mobile
                  phones, these service providers are the best solution for
                  people whose phone is not functioning properly. These mobile
                  repairs centers are actually of great use as they have
                  expertise in their segment of work. They are professionals
                  with in-depth knowledge of all phones irrespective of the
                  shape, size, type or brand. Any problem can be solved in as
                  less as two to three hours. In cases of part replacement, it
                  may take longer if the part isn’t available with the vendor or
                  needs to be specially ordered.
                </p>
                <p>
                  Whatever is the reason, user get so many beneficial while
                  having mobile repairing shops near you and you will get a
                  Hurry up! Visit us or Call us: <span>+91 8800 653 465</span>{" "}
                  to book online.{" "}
                </p>

                <div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>

            <div id="blog-4">
              <div className="blog-section-img">
                {" "}
                <img
                  src="images/iphonescreenfix.jpg"
                  alt="FixScreenRepair insecure"
                />{" "}
              </div>
              <div className="blog-section-content">
                <h4>
                  Fix Your Iphone Issues With The Assistance Of The Best Service
                  Providers
                </h4>
                <p>
                  today time mobile very important And the reason behind it is
                  quite evident and Accidents will happen and your mobile gets
                  Screen damaged first and you will very sad now urgent reached
                  shop and shop owner speak you you come tomorrow you think
                  Doorstep repairing at its best mobile repair with
                  FixScreenRepair. Hurry up! Visit us or Call us:{" "}
                  <span>+91 8800 653 465</span> to book online.{" "}
                </p>
              </div>
            </div>

            <div id="blog-1-1">
              <div className="blog-section-img">
                {" "}
                <img
                  src="images/insecure.jpg"
                  alt="FixScreenRepair insecure"
                />{" "}
              </div>
              <div className="blog-section-content">
                <h4>About Fix Screen Repair</h4>
                <p>
                  Fix Screen Repair is well established ventures which is
                  started by and Kavita Singh in 2018. Today, we are very busy
                  in our day to day life and people don’t have time to even do
                  some extra stuffs in their life and mobile made them very
                  important and essential component for any user.{" "}
                </p>
                <p>
                  So, when anyone is using their phone and sudden, it got
                  damaged by means mode of operation either by loosing from hand
                  or by any normal accident. Everyone keeps all the information
                  in their phone and once that gets damage, it becomes heart
                  attack for the user and after thinking all these, we thought
                  to establish something, so, user doesn’t have to visit to the
                  mobile repair shop for repairing their mobile because mobile
                  repair shop takes 4-5 hours to repair and sometime, it takes
                  around a week to get it repair and user can’t stay alone even
                  a single second without the mobile.{" "}
                </p>
                <p>
                  So, we are providing such a service, so, user can reach to our
                  site and register the complain for their make and model of the
                  mobile and we will reach to your doorstep within 2 hours and
                  fix your mobile issue on the spot without destroying your
                  valuable time.{" "}
                </p>
                <p>
                  We are having a team of 50 people who is servicing throughout
                  the Delhi NCR.
                </p>
              </div>
            </div>
            <div id="blog-1">
              <div className="blog-section-img">
                {" "}
                <img
                  src="images/insecure.jpg"
                  alt="FixScreenRepair insecure"
                />{" "}
              </div>
              <div className="blog-section-content">
                <h4>
                  Are you looking for an Online Mobile Repairing company in
                  Delhi/NCR?
                </h4>
                <p>
                  If you are looking for Online Mobile Repairing Company then
                  your search ends at FixScreenRepair. We are not only service
                  center of online mobile repair but also known for doing best
                  repair of all type of mobile phones in Delhi, Noida and
                  Gurgaon. The majority of the online mobile repair centers have
                  a great deal of heavy charges but at our company
                  FixScreenRepair. You just need to pay normal charges for your
                  mobile phone repair. Hurry up! Visit us or Call us:{" "}
                  <span>+91 8800 653 465</span> to book online.{" "}
                </p>
              </div>
            </div>

            <div id="blog-2">
              <div className="blog-section-img">
                {" "}
                <img
                  src="images/blog-1.jpg"
                  alt="FixScreenRepair blog-1"
                />{" "}
              </div>
              <div className="blog-section-content">
                <h4>
                  Top Things To Remember Before Handing Your Smartphone To The
                  Expert
                </h4>
                <p>
                  Is your Smart Phone not working and you are planning to give
                  your phone to an expert for repair? If yes then you must know
                  about your expert technical. Many online mobile repair
                  companies will ask for a security password and pattern lock,
                  while they receiving your smartphone for repair. You should be
                  careful about your personal information and more….{" "}
                </p>
              </div>
            </div>
            <div id="blog-3">
              <div className="blog-section-img">
                {" "}
                <img
                  src="images/blog-2.jpg"
                  alt="FixScreenRepair blog-2"
                />{" "}
              </div>
              <div className="blog-section-content">
                <h4>
                  Here are some common issues in phone and tips to recover from
                  them
                </h4>
                <p>
                  Case in point is iPhone, where the glorious manufacturer Apple
                  charges $129 with taxes to be paid extra for iPhone 6S while
                  for iPhone 6S Plus, it stands at $149 with taxes payable
                  additionally. Likewise, AppleCare+ is Apple Inc’s policy which
                  covers accidental damages but if an iPhone is not covered
                  under any such policy, getting our iPhone repaired at Apple is
                  indeed a backbreaking experience owing to high price tag.{" "}
                </p>
              </div>
            </div>

            <div id="blog-4">
              <div className="blog-section-img">
                {" "}
                <img
                  src="images/blog-3.jpg"
                  alt="FixScreenRepair blog-3"
                />{" "}
              </div>
              <div className="blog-section-content">
                <h4>Have You Ever Cleaned Up Your Cell Phone? </h4>
                <p>
                  OH Great! You are looking for best online shop that can help
                  you to clean your cell phone then here at FixScreenRepair.com
                  your search ends. We are reliable Mobile phone repair shop in
                  Delhi/NCR. My Question is "have you ever cleaned up your cell
                  phone"? If no then please do it as soon as possible. We will
                  remove junk files and the hundreds of bad photos to gain some
                  storage space from your mobile phone. It will help to increase
                  performance/Speed of your cell phone. Call Today!{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="blog-post-right">
            <div className="post-header"> recent posts </div>
            <div className="recent-post-list">
              <ul>
                <li>What is Doorstep Mobile Repair</li>

                <li>Get Your IOS Android Phone At Your Doorstep Now</li>
                <li>
                  Fix Your Iphone Issues With The Assistance Of The Best Service
                  Providers
                </li>
                <li>
                  Fix Your Iphone Issues With The Assistance Of The Best Service
                  Providers
                </li>
                <li>
                  Are you looking for an Online Mobile Repairing company in
                  Delhi/NCR?
                </li>
                <li>
                  Top Things To Remember Before Handing Your Smartphone To The
                  Expert
                </li>
                <li>
                  Here are some common issues in phone and tips to recover from
                  them
                </li>
              </ul>
            </div>
            <div className="post-header"> ARCHIVES </div>
            <div className="recent-post-list archives"></div>
            <div className="post-header"> CATEGORIES </div>
            <div className="recent-post-list categories"></div>
          </div>
        </div>
      </div>
      <div className="clear"> </div>
    </div>
  );
}

export default Blog;
