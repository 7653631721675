import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import NavBar from "../src/component/Navbar";
import Home from './page/Home';
import About from './page/About';
import Contact from './page/Contact';
import Gallery from './page/Gallery';
import Offers from './page/Offers';
import Blog from './page/Blog';

import Footer from './component/Footer';
function App() {
  return (
    <Router>
      <NavBar />
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/about" component={About} />
      <Route path="/gallery" component={Gallery} />
      <Route path="/offers" component={Offers} />
      <Route path="/blog" component={Blog} />
      <Route path="/contact" component={Contact} />
    </Switch>
    <Footer />
  </Router>





  );
}

export default App;


