import React from "react";

function Gallery() {
  return (
    <div>
      <section className="padding-top">
        <div className="section-about transparent-bg">
          <img src="images/gallery.jpg" alt="FixScreenRepair gallery" />
        </div>
      </section>
      <section>
        <div className="container">
          <div className="section-title padding-top-90">
            <h1>
              <span>We work professionally</span>
            </h1>
          </div>
          <div className="gallary-main">
            <div className="item-row">
              <div className="gallary-item gallery-bg-1"></div>
              <div className="gallary-item gallary-push-down">
                <span>
                  <b>Before Repair</b>
                  <img src="images/1-1.png" alt="FixScreenRepair gallery1" />
                </span>
                <span>
                  <b>After Repair</b>
                  <img src="images/1.png" alt="FixScreenRepair gallery2" />
                </span>
              </div>
            </div>
            <div className="item-row">
              <div className="gallary-item gallary-push-up">
                <span>
                  <b>Before Repair</b>
                  <img src="images/2-1.png" alt="FixScreenRepair gallery3" />
                </span>
                <span>
                  <b>After Repair</b>
                  <img src="images/2.png" alt="FixScreenRepair gallery4" />
                </span>
              </div>
              <div className="gallary-item gallary-push-down gallery-bg-2"></div>
            </div>
            <div className="item-row">
              <div
                className="gallary-item gallery-bg-3"
                alt="FixScreenRepair gallery5"
              ></div>
              <div className="gallary-item gallary-push-down">
                <span>
                  <b>Before Repair</b>
                  <img src="images/4-1.png" alt="FixScreenRepair gallery6" />
                </span>
                <span>
                  <b>After Repair</b>
                  <img src="images/4.png" alt="FixScreenRepair gallery7" />
                </span>
              </div>
            </div>
            <div className="item-row">
              <div className="gallary-item gallary-push-up">
                <span>
                  <b>Before Repair</b>
                  <img src="images/before.png" alt="FixScreenRepair gallery8" />
                </span>
                <span>
                  <b>After Repair</b>
                  <img src="images/after.png" alt="FixScreenRepair gallery9" />
                </span>
              </div>
              <div className="gallary-item gallary-push-down gallery-bg-4"></div>
            </div>
          </div>
        </div>
      </section>
      <div className="clear"> </div>
      <section>
        <div className="estimate-section bg-banner-contact">
          <div className="estimate-txt-center">
            <h2>Looking For A Fast & Reliable Repair Service</h2>
            <p>Get instant repair prices in just a few clicks</p>
            <p>
              <a href="/" className="view-btn">
                Get Your Phone Estimate
              </a>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Gallery;
