import React from "react";
import {
  Facebook,
  Twitter,
  Instagram,
  Youtube,
  Linkedin,
} from "react-bootstrap-icons";

const Footer = () => {
  return (
    <>
      <footer className="main-footer">
        <div className="container">
          <div className="div-main-footer-col">
            <div className="div-footer-col1">
              <h2 className="footer-header">
                Offering you the best solution for your mobile!
              </h2>
              We are the #1 smart device repair centre based in Noida and we
              have expanded our reach throughout India. We specialize in all
              types of mobile repairs and we have a crew of highly trained and
              efficient technicians.
              <h5 className="footer-header2">What we Offer</h5>
              Having a customer base of 7,000 by the end of first year, now
              serving over 1.1 lacs customers across 4 cities with a monthly
              growth of 5% along with 40% repeat and referred customers. Delhi/
              Noida/ GR Noida/ ghaziabad
            </div>
            

            <div className="div-footer-col">
              <div className="footer-link">
                <ul>
                  Serviceable Locations
                  <li>
                  Mobile Repair Service In Noida
                  </li>
                  <li>
                  Mobile Repair Service In GR Noida
                  </li>
                  <li>
                  Mobile Repair Service In Ghaziabad
                  </li>
                  <li>
                  Mobile Repair Service In Delhi
                  </li>
                </ul>
              </div>
            </div>
            <div className="div-footer-col">
              <div className="footer-col">
             
                <img src="images/logo-repair.png" alt="FixScreenRepair-logo" />
              </div>
              <div className="social-media contact-media footer-media">
                <ul>
                  <li>
                    <Facebook />

                  </li>
                  <li>
                    <Twitter />

                  </li>
                  <li>
                    <span>
                      <Instagram />
                    </span>
                  </li>
                  <li>
                    <span>

                      <Linkedin />
                    </span>
                  </li>
                  <li>
                    <span>

                      <Youtube />
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="dv-copyright"> © 2016 - 2021 All rights reserved. </div>
      </footer>
    </>
  );
};
export default Footer;
