import React from "react";
import { Link } from "react-router-dom";
function Contact() {
  return (
    <div>
      <section className="padding-top">
        <div className="section-about transparent-bg">
          <img src="images/contact.jpg" alt="FixScreenRepair contact" />

          <div className="clearfix"></div>
        </div>
      </section>
      <section className="contact-main">
        <div className="container">
          <div className="col-half padding-right0">
            <div className="form-width bg-white">
              <div className="main-contact-form">
                <div className="form-group">
                  <div className="col-half">
                    <div className="contact-detail">
                      <p>
                      <span className="fa fa-phone"></span> +91 8800653465
                      </p>
                    </div>
                  </div>
                  <div className="col-half">
                    <div className="contact-detail">
                      <p>
                      <span className="fa fa-phone"></span> +91 8178806571
                      </p>
                    </div>
                  </div>
                  <div className="col-half">
                    <div className="contact-detail">
                      <p>
                      contact@FixScreenRepair.com
                      </p>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                </div>
                <div className="form-group">
                  <div className="address">
                    <p>
                      <span className="fa fa-map-marker"></span>
                      <address>
                        Tuesday Market Vijay Nagar (Ghaziabad) Pin 201009
                      </address>
                    </p>
                  </div>
                </div>

                <form action="" method="POST">
                  <div className="help-text">How can we help you</div>
                  <div className="form-group">
                    <label>Full Name</label>
                    <input
                      type="text"
                      className="form-control contactUsPage"
                      placeholder=""
                      name="FullName"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Mobile</label>
                    <input
                      type="tel"
                      className="form-control contactUsPage"
                      placeholder=""
                      name="Mobile"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      name="Email"
                    />
                  </div>
                  <div className="form-group">
                    <label>Description</label>
                    <textarea
                      className="contact-textAria form-control"
                      placeholder=""
                      name="AddComment"
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <input
                      type="submit"
                      className="contact-submit-box"
                      value="Send"
                      name="submit"
                      disabled
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
          <div className="col-half padding-left0">
            <div className="mapouter">
              <div className="gmap_canvas">
                <iframe
                  title="Google Map"
                  width="100%"
                  height="580px"
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?q=vijay%20nagar%20gaziabad&t=&z=19&ie=UTF8&iwloc=&output=embed"
                  frameBorder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                ></iframe>
             
              </div>
            </div>
          </div>
          <div className="clearfix"></div>
        </div>
      </section>

      <section>
        <div className="estimate-section bg-banner-contact">
          <div className="estimate-txt-center">
            <h2>Looking For A Fast & Reliable Repair Service</h2>
            <p>Get instant repair prices in just a few clicks</p>
            <p>
            <Link to="/" className="view-btn">Get Your Phone Estimate </Link>
             
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact;
