

import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Carousel from "react-bootstrap/Carousel";
import axios from 'axios';
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const spanStyle = {
  padding: "20px",
  background: "#efefef",
  color: "#000000",
};

const divStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundSize: "cover",
  height: "650px",
};
const slideImages = [
  {
  url: "images/1.jpg",
  // caption: 'Slide 1'
  },
  {
  url: "images/2.jpg",
  },
  {
  url: "images/3.jpg",
  },
  {
  url: "images/4.jpg",
  },
  {
  url: "images/5.jpg",
  },
  {
  url: "images/6.jpg",
  },
];

function Home() {
  const [options, setOptions] = useState([]);
  const [brandId, setBrandId] = useState('');
  const [modeldId, setModeldId] = useState('');

  const [mobileModel, setmobileModel]  = useState([]);
  const [mobileModelColor, setmobileModelColor]  = useState([]);

  useEffect(() => {
  axios.get('https://fsr-prod-api.fixscreenrepair.com/api/v1/Brands')
    .then(response => {
    setOptions(response.data);
    })
    .catch(error => {
    console.error('Error fetching data: ', error);
    });
  }, []);

  useEffect(() => {
  
  if (brandId) {     
    axios.get(`https://fsr-prod-api.fixscreenrepair.com/api/v1/Model/Brand/${brandId}`)
    .then(response => {
    setmobileModel(response.data);
    })
    .catch(error => {
      console.error('Error fetching data: ', error);
    });
  }
  }, [brandId]);

  const handleSelectChange = (event) => {
  debugger
  const selectedBrandId = event.target.value; 
  setBrandId(selectedBrandId);
  };

  useEffect(() => {

  if (modeldId) {     
    axios.get(`https://fsr-prod-api.fixscreenrepair.com/api/v1/Mobile/Model/${modeldId}`)
    .then(response => {
      setmobileModelColor(response.data);
    })
    .catch(error => {
      console.error('Error fetching data: ', error);
    });
  }
  }, [modeldId]);

  const handleSelectChangeColor = ($event) => {
  const selectedModelId = $event.target.value; 
  setModeldId(selectedModelId);
  };


  const [isHidden, setIsHidden] = useState(false);

  const toggleDiv = () => {
  setIsHidden(!isHidden);
  };
  return (
  <div>
    {!isHidden && (
    <div className="comman-edit-popup-main" id="video-feedback-popup">
      <div className="edit-popup-body discover-alert-dialog">
      <div className="popup-close" id="Ghaziabad" onClick={toggleDiv}>
        <img src="images/cross.png" alt="FixScreenRepair close" />
      </div>
      <div className="location-main-dv">
        <h3 className="location-title">
        I'm looking for <span>Doorstep Mobile Screen Replacement</span>{" "}
        in
        </h3>
        <div className="location-dv">
        <ul className="location-list">
          <li id="Delhi">
          <img src="images/delhi.jpg" alt="FixScreenRepair delhi" />
            <span name="delhi">Delhi</span>
          </li>
          <li id="Noida">
          <img src="images/noida.jpg" alt="FixScreenRepair noida" />
            <span name="noida">Noida</span>
          </li>
          <li id="Greater Noida">
          <img
            src="images/gr-noida.jpg"
            alt="FixScreenRepair gr-noida"
            />
            <span name="greaterNoida">Greater Noida</span>
          </li>
          <li id="Ghaziabad">
          <img
            src="images/Ghaziabad.jpg"
            alt="FixScreenRepair Ghaziabad"
            />
            <span name="ghaziabad">Ghaziabad</span>
          </li>

          <li id="Gurgaon">
          <img
            src="images/gurgaon.jpg"
            alt="FixScreenRepair gurgaon"
            />
            <span name="gurgaon">Gurgaon</span>
          </li>
        </ul>
        </div>
      </div>
      </div>
    </div>
    )}
    {/* <!--Banner--> */}
    <div className="slide-container">
    <Slide>
      {slideImages.map((slideImage, index) => (
      <div key={index}>
        <div
        style={{
          ...divStyle,
          backgroundImage: `url(${slideImage.url})`,
        }}
        >
        <span style={spanStyle}>{slideImage.caption}</span>
        </div>
      </div>
      ))}
    </Slide>
    </div>

    <div>
    <div className="banner-form-div">
      <div className="banner-form-header">
      {" "}
      Get Your Phone Repair at Wherever You Are!
      </div>
      <Form>
      <div>
        <ul>
        <li>   <label> Select an Brand</label>
           <Form.Control as="select" custom onChange={handleSelectChange}>
          
  <option value="">Select an Brand</option>
  {options.map(option => (
  <option key={option.value} value={option.id}>{option.name}</option>
  ))}
</Form.Control>
</li>
<li>  <label> Select an Model</label>
<Form.Control as="select" custom onChange={handleSelectChangeColor}>

  <option value="">Select an Model</option>
  {mobileModel.map(mobileModelName => (
  <option key={mobileModelName.value} value={mobileModelName.id}>{mobileModelName.name}</option>
  ))}
</Form.Control>
</li>

<li>  <label> Select an Model</label>
<Form.Control as="select">

  <option value="">Select an color</option>
  {mobileModelColor.map(mobileModelColor => (
  <option key={mobileModelColor.value} value={mobileModelColor.modelId}>{mobileModelColor.color}</option>
  ))}
</Form.Control>
</li>



<li className='banner-form-btn'>
<input
          type="submit"
          id="mainSubmitBtn"
          value="Go"
          name="submit"
          disabled
          />
</li>
        </ul>
      </div>

         
  </Form>
    </div>
    </div>

    <section className="section-padding">
    <div className="container">
      <div className="about-dv">
      <div className="about-text">
        <div className="section-title">
        <h1>
          <span>
          Mobile Phone Screen Repair and Replacement Service by
          FixScreenRepair
          </span>
        </h1>
        </div>
        <p>
        {" "}
        Welcome at <span>Fix Screen Repair</span>. We are a leading
        mobile repairs company in India based in Noida and we having
        team of passionate and highly expert engineers who wants to
        revolutionize the mobile repairs industry by making your mobile
        repairs service process as smooth as your purchase of new
        mobile.{" "}
        </p>
        <p>
        {" "}
        When any person give their mobile phone repair at{" "}
        <span>Fix Screen Repair</span> than we starts with a free
        analysis to find the main problem and allow the technician to
        determine the right mobile phone screen repair path. Once the
        analysis of phone complete, the technician can explain all of
        your mobile phone screen repair options and get started if you
        want to proceed. We at <span>FixScreenRepair</span> promise to
        serve you with the best of our ability and resources available
        herewith.
        </p>
        <p>
        <span className="view-btn">
        View Repair Price
        </span>
        </p>
      </div>
      <div className="about-video">
        <iframe
        title="FixScreenRepair video"
        src="https://www.youtube.com/embed/uJsfxzo8cYg"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen=""
        ></iframe>{" "}
      </div>
      </div>
    </div>
    </section>

    <section className="brand-bg-color">
    <div className="section-title">
      <h2>
      <span>Brands We Serve by FixScreenRepair</span>
      </h2>
    </div>
    <div className="container hide-next-pre-icon">
      <Carousel data-bs-theme="dark">
      <Carousel.Item>
        <div className="small-slider">
        <ul>
          <li>
          {" "}
          <img src="images/asus.jpg" alt="First slide" />
          </li>
          <li>
          {" "}
          <img src="images/gionee.jpg" alt="First slide" />
          </li>
          <li>
          {" "}
          <img src="images/huawei.jpg" alt="First slide" />
          </li>
          <li>
          {" "}
          <img src="images/iphone.jpg" alt="First slide" />
          </li>
        </ul>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="small-slider">
        <ul>
          <li>
          {" "}
          <img src="images/leeco.jpg" alt="First slide" />
          </li>
          <li>
          {" "}
          <img src="images/Lenovo.jpg" alt="First slide" />
          </li>
          <li>
          {" "}
          <img src="images/moto.jpg" alt="First slide" />
          </li>
          <li>
          {" "}
          <img src="images/nexus.jpg" alt="First slide" />
          </li>
        </ul>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="small-slider">
        <ul>
          <li>
          {" "}
          <img src="images/nokia.jpg" alt="First slide" />
          </li>
          <li>
          {" "}
          <img src="images/OnePlus.jpg" alt="First slide" />
          </li>
          <li>
          {" "}
          <img src="images/oppo.jpg" alt="First slide" />
          </li>
          <li>
          {" "}
          <img src="images/vivo.jpg" alt="First slide" />
          </li>
        </ul>
        </div>
      </Carousel.Item>

      <Carousel.Item>
        <div className="small-slider">
        <ul>
          <li>
          {" "}
          <img src="images/nokia.jpg" alt="First slide" />
          </li>
          <li>
          {" "}
          <img src="images/OnePlus.jpg" alt="First slide" />
          </li>
          <li>
          {" "}
          <img src="images/mi.jpg" alt="First slide" />
          </li>
          <li>
          {" "}
          <img src="images/samsung-logo.jpg" alt="First slide" />
          </li>
        </ul>
        </div>
      </Carousel.Item>
      </Carousel>
    </div>
    </section>
    <section className="section-padding bg-img-gray">
    <div className="container">
      <div className="section-title">
      <h2>
        <span>Offering You The Best Solution For Your Mobile Repair</span>
      </h2>
      </div>

      <div className="offering-text">
      <span className="text-screenpro">Fix Screen Repair</span> is a leading
      mobile phone repair service provider based in Ghaziabad, Delhi/NCR
      India. We specialize in all mobile phone repairs & replacement. Our
      repairs are conducted by highly trained technicians with sound
      knowledge & they having more than 8 years of experience in this
      field.
      </div>

      <div className="service-main">
      <div className="service-col">
        <div className="service-icon">
        {" "}
        <img src="images/easy-2.png" alt="FixScreenRepair easy" />
        </div>
        <div className="service-step-div">Always Available For You</div>
        <p>
        We at <span className="text-screenpro">FixScreenRepair</span> try to
        always available for our Customer Service. Our Top priority is
        Customer satisfaction which is more important than anything
        else. We are just one phone call away with our Free Pickup and
        delivery policy.
        </p>
      </div>
      <div className="service-col">
        <div className="service-icon">
        {" "}
        <img src="images/tag.png" alt="FixScreenRepair tag" />{" "}
        </div>

        <div className="service-step-div">Unbeatable Price</div>
        <p>
        {" "}
        Our mobile repair price of your device will be very affordable.
        You will get the best market price for any part repair &
        replacement of your device from{" "}
        <span className="text-screenpro">FixScreenRepair</span>. Our
        warranty starts from 6 Months to Lifetime. To know more visit
        our website today or Call us.
        </p>
      </div>
      <div className="service-col">
        <div className="service-icon">
        {" "}
        <img
          src="images/gaurantee.png"
          alt="FixScreenRepair gaurantee"
        />{" "}
        </div>
        <div className="service-step-div">Express Service</div>
        <p>
        {" "}
        We know how important a mobile phone is in our day to day life
        and for this <span className="text-screenpro">
          FixScreenRepair
        </span>{" "}
        provides same day Free Pickup and delivery service. Our expert
        will collect your phone at your home or office faster than
        anyone else.
        </p>
      </div>
      <div className="clearfix"></div>
      </div>
    </div>
    </section>

    <section>
    <div className="estimate-section bg-banner-contact">
      <div className="estimate-txt-center">
      <h2>Looking For A Fast &amp; Reliable Repair Service</h2>
      <p>Get instant repair prices in just a few clicks</p>
      <p>
        <span className="view-btn">
        Get Your Phone Estimate
        </span>
      </p>
      </div>
    </div>
    </section>

    <section className="section-padding bg-img-gray">
    <div className="container">
      <div className="section-title">
      <h1>
        <span>
        Why Choose FixScreenRepair for Mobile Repair and Replacement?
        </span>
      </h1>
      </div>

      <div className="offering-text">
      India's Most Recognized
      <span className="text-screenpro">
        {" "}
        Mobile Phone Screen Repair and Replacement Company in Delhi/NCR
      </span>
      . Having served 2000+ clients since 2017, we have successfully
      repaired Smart Phones and iPhones and make it working for lifelong.
      Our main AIM is to give quality work and satisfaction to our
      customers.
      </div>

      <div className="service-main">
      <div className="service-col">
        <div className="service-icon">
        <img src="images/trainee.png" alt="FixScreenRepair trainee" />
        </div>
        <div className="service-step-div">Trained & Certified Team</div>
        <p>
        {" "}
        Our certified technician and experienced team will repair your
        mobiles & tablets at your preferred location in timely. Just a
        call away!
        </p>
      </div>
      <div className="service-col">
        <div className="service-icon">
        <img src="images/secure.png" alt="FixScreenRepair secure" />
        </div>

        <div className="service-step-div">Reliable & Secure</div>
        <p>
        Very Initial stage we understand the importance of data,
        therefore, we make our customer assure to keep it safe. We offer
        Reliable & Secure Services totally hassle free!
        </p>
      </div>
      <div className="service-col">
        <div className="service-icon">
        <img
          src="images/mobile-part.png"
          alt="FixScreenRepair mobile-part"
        />
        </div>
        <div className="service-step-div">Genuine Parts</div>
        <p>
        {" "}
        When it comes to repairing your damaged brand new phone. We
        assure our customers for Genuine Parts at the lowest cost in
        comparison to our competitors.
        </p>
      </div>
      <div className="clearfix"></div>
      </div>

      <div className="service-main">
      <div className="service-col">
        <div className="service-icon">
        <img
          src="images/quick-repair.png"
          alt="FixScreenRepair quick-repair"
        />
        </div>
        <div className="service-step-div">
        Quick Repair Process
        </div>
        <p>
        {" "}
        Every customer wants quick repair of their mobile phones, we
        value our customer's time, therefore, our team repair mobile
        devices at their doorstep on time.
        </p>
      </div>
      <div className="service-col">
        <div className="service-icon">
        <img
          src="images/low-price.png"
          alt="FixScreenRepair low-price"
        />
        </div>

        <div className="service-step-div">Low Price Guarantee</div>
        <p>
        {" "}
        When you will compare our price with our competitors – You will
        feel very happy, because of our low price & best services for
        your device repair.
        </p>
      </div>
      <div className="service-col">
        <div className="service-icon">
        <img src="images/waranty.png" alt="FixScreenRepair waranty" />
        </div>
        <div className="service-step-div">180 Days Warranty</div>
        <p>
        {" "}
        We provide six months warranty for any part replaced by the
        company. Term and condition apply* what's more, you need?
        </p>
      </div>
      <div className="clearfix"></div>
      </div>
    </div>
    </section>

    <section className="section-padding bg-gray-2">
    <div className="counter-main">
      <div className="container">
      <div className="counter-col">
        {" "}
        <img src="images/stat1.png" alt="FixScreenRepair stat1" />
        <h2>100%</h2>
        <p>Satisfaction Guaranteed </p>
      </div>
      <div className="counter-col">
        {" "}
        <img src="images/stat2.png" alt="FixScreenRepair stat2" />
        <h2>5</h2>
        <p>Years in Market </p>
      </div>
      <div className="counter-col">
        {" "}
        <img src="images/stat3.png" alt="FixScreenRepair stat3" />
        <h2>5000</h2>
        <p>Happy Customers </p>
      </div>
      <div className="counter-col">
        {" "}
        <img src="images/stat4.png" alt="FixScreenRepair stat4" />
        <h2>6500</h2>
        <p>Items Repaired </p>
      </div>
      <div className="clearfix"></div>
      </div>
    </div>
    </section>
    <div className="clientBanner">
    <div className="container">
      <div className="section-title">
      <h1>
        <span> Our Customers say the great things </span>{" "}
      </h1>
      </div>

      <Carousel data-bs-theme="dark" className="hide-next-pre-icon">
      <Carousel.Item>
        <div className="row">
        <div className="col-sm-3 text-center clientCarouselImg">
          <img
          className="img-circle"
          src="images/nisha.jpg"
          alt="FixScreenRepair client"
          />
        </div>
        <div className="col-sm-9 clientCarouselRight">
          <h3 className="clientCarouselTitle">Nisha Singh</h3>
          <p>
          Life comes to a stop when our smartphones give us problems
          or when its screen is broken. But luckily we have
          FixScreenRepair to depend upon to sort out our issues and to
          provide long lasting satisfaction. I hail this agency.
          </p>
        </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="row">
        <div className="col-sm-3 text-center clientCarouselImg">
          <img
          className="img-circle"
          src="images/herandra.jpg"
          alt="FixScreenRepair client"
          />
        </div>
        <div className="col-sm-9 clientCarouselRight">
          <h3 className="clientCarouselTitle">Herandra Verma</h3>
          <p>
          This group is so well organized that within hours they can
          provide assistance. My mobile is a costly product and Swaraj
          ji is simply the boss of mobile repair in Delhi.
          </p>
        </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="row">
        <div className="col-sm-3 text-center clientCarouselImg">
          <img
          className="img-circle"
          src="images/upendra.jpg"
          alt="FixScreenRepair client"
          />
        </div>
        <div className="col-sm-9 clientCarouselRight">
          <h3 className="clientCarouselTitle">Upendra Sharma</h3>
          <p>
          I recommend FixScreenRepair as being the most versatile
          group of engineers who have evolved their skills to match
          the latest range of mobile phones. hey can check, diagnose
          and can rectify the problem within minutes, with an extended
          warranty.
          </p>
        </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="row">
        <div className="col-sm-3 text-center clientCarouselImg">
          <img
          className="img-circle"
          src="images/ankita.jpg"
          alt="FixScreenRepair client"
          />
        </div>
        <div className="col-sm-9 clientCarouselRight">
          <h3 className="clientCarouselTitle"> Ankita Dubey </h3>
          <p>
          This is a good positive effort to provide an easy solution
          to smartphone problems.In busy schedule of today, they are
          the pillar of hope else broken smartphones are no lesser
          than a catastrophe.
          </p>
        </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="row">
        <div className="col-sm-3 text-center clientCarouselImg">
          <img
          className="img-circle"
          src="images/rohit.jpg"
          alt="FixScreenRepair client"
          />
        </div>
        <div className="col-sm-9 clientCarouselRight">
          <h3 className="clientCarouselTitle">Rohit Gupta</h3>
          <p>
          Truly professional bunch of mobile engineers who offer
          mobile repair service at reasonable prices. I regard this
          young group as the hope of future mobile engineers. I wish
          them best of luck....
          </p>
        </div>
        </div>
      </Carousel.Item>
      </Carousel>
    </div>
    </div>
  </div>
  );
}

export default Home;


